import createReducer, { RESET_STORE } from "../createReducer";
import { loginSuccess } from "./login";
import { PREV_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE, TOKEN_COOKIE, SHORT_MESSAGE_DELAY, LONG_MESSAGE_DELAY, COSMETIC } from "../constants";
import { getIntl } from "./intl";
import { getFormErrors } from "../utils";
import messages from "./messages";
import { message } from "antd";
import { changeCurrency } from "./global";
import { signOut } from "./product";
import { cleardilution } from "./dilution";
import {clearBPMessages} from "./bpai";
import axios from "../config-axios";

// ------------------------------------
// Constants
// ------------------------------------
export const LOGOUT_REQUEST = "User.LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "User.LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "User.LOGOUT_FAILURE";

export const GET_USER_REQUEST = "User.GET_USER_REQUEST";
export const GET_USER_SUCCESS = "User.GET_USER_SUCCESS";
export const GET_USER_FAILURE = "User.GET_USER_FAILURE";

export const CANCEL_SUBSCRIPTION_REQUEST = "User.CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "User.CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "User.CANCEL_SUBSCRIPTION_FAILURE";

export const REFRESH_TOKEN_REQUEST = "User.REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "User.REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "User.REFRESH_TOKEN_FAILURE";

export const UPDATE_USER_REQUEST = "User.UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "User.UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "User.UPDATE_USER_FAILURE";

export const OPEN_UPDATE_USER_MODAL = "User.OPEN_UPDATE_USER_MODAL";
export const CLOSE_UPDATE_USER_MODAL = "User.CLOSE_UPDATE_USER_MODAL";

export const UPDATE_PASSWORD_REQUEST = "User.UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "User.UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "User.UPDATE_PASSWORD_FAILURE";

export const OPEN_UPDATE_PASSWORD_MODAL = "User.OPEN_UPDATE_PASSWORD_MODAL";
export const CLOSE_UPDATE_PASSWORD_MODAL = "User.CLOSE_UPDATE_PASSWORD_MODAL";

export const UPDATE_SESSION_CURRENCY = "User.UPDATE_SESSION_CURRENCY";
export const CLEAR_SESSION_CURRENCY = "User.CLEAR_SESSION_CURRENCY";

let refreshingToken = false;

// ------------------------------------
// Actions
// ------------------------------------

export const expireToken =
  () =>
  (dispatch, getState, { cookies }) => {
    cookies.remove(TOKEN_COOKIE, { path: "" });
    cookies.remove(REFRESH_TOKEN_COOKIE, { path: "" });
    cookies.remove(PREV_TOKEN_COOKIE, { path: "" });
  };

export const logoutSuccess =
  () =>
  (dispatch, getState, { cookies }) => {
    for (const item in cookies.getAll()) {
      if (/^crisp/.test(item)) {
        cookies.remove(item, { path: "" });
      }
    }

    try {
      dispatch(cleardilution());
      dispatch(expireToken());
      dispatch({ type: LOGOUT_SUCCESS });
    } catch (err) {
      console.log("failed to log out gracefully");
    }
  };

export const handleStripePortalRedirect =
  () =>
  async (dispatch, getState, { fetch }) => {
    const { apiUrl } = getState().global;
    const { token } = dispatch(getToken());

    try {
      const response = await axios.post(
        apiUrl + "/payments/create-portal-session/",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );

      const portalUrl = response.data.url;
      window.location.href = portalUrl;
    // } catch (error) {
    //   console.error("Error creating customer portal session:", error);
    //   alert(`There was an error redirecting to the customer portal. ${error}`);
    } catch (error) {
      // Log the full error object
      console.error("Full error object:", error);
      
      // Log the error response data safely
      const errorData = error.response && error.response.data;
      console.error("Error response data:", errorData);
      
      // Create a more detailed error message
      const errorMessage = errorData ? errorData.error : error.message;
      alert(`There was an error redirecting to the customer portal: ${errorMessage}`);
    }      
  };

export const refreshToken =
  (token, refreshToken) =>
  (dispatch, getState, { fetch, history }) => {
    if (refreshingToken) {
      return;
    }
    refreshingToken = true;
    dispatch({ type: REFRESH_TOKEN_REQUEST });
    const { clientId, clientSecret, currentPathname } = getState().global;
    return fetch("/auth/token/", {
      method: "POST",
      contentType: "application/x-www-form-urlencoded",
      body: {
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: "refresh_token",
        refreshToken,
      },
      success: (res) => {
        message.info("Access Token has been refreshed.  Please retry last operation", LONG_MESSAGE_DELAY);
        refreshingToken = false;
        dispatch(loginSuccess(res, true, currentPathname));
        dispatch({ type: REFRESH_TOKEN_SUCCESS });
      },
      // eslint-disable-next-line node/handle-callback-err
      failure: (err) => {
        refreshingToken = false;
        dispatch(logoutSuccess());
        dispatch({ type: REFRESH_TOKEN_FAILURE });
        if (process.env.BROWSER) {
          // refresh page to resend requests
          history.replace(currentPathname);
        }
      },
    });
  };

export const getToken =
  () =>
  (dispatch, getState, { history, cookies }) => {
    const { currentPathname } = getState().global;
    const { loggedIn } = getState().user;
    const token = cookies.get(TOKEN_COOKIE);
    const refreshTokenCookie = cookies.get(REFRESH_TOKEN_COOKIE);
    const prevTokenCookie = cookies.get(PREV_TOKEN_COOKIE);

    if (!token) {
      if (prevTokenCookie && refreshTokenCookie) {
        dispatch(refreshToken(prevTokenCookie, refreshTokenCookie));
      } else if (loggedIn) {
        dispatch(logoutSuccess());
        if (process.env.BROWSER) {
          // refresh page to resend requests
          history.replace(currentPathname);
        }
      }
      return {};
    }

    return { token, refreshTokenCookie };
  };

export const resetLogin =
  () =>
  (dispatch, getState, { fetch }) => {
    signOut(dispatch);
  };

export const logout =
  () =>
(dispatch, getState, { fetch }) => {
    dispatch({ type: "bpai.CLEAR_CHAT_MESSAGES" });          
    dispatch(clearBPMessages);
    signOut(dispatch);
    const { clientId, clientSecret } = getState().global;
    const { token } = dispatch(getToken());
    dispatch({ type: LOGOUT_REQUEST });
    return fetch("/auth/revoke-token/", {
      method: "POST",
      contentType: "application/x-www-form-urlencoded",
      token,
      body: {
        client_id: clientId,
        client_secret: clientSecret,
        token,
      },
      success: (res) => dispatch(logoutSuccess(res)),
      failure: (err) => dispatch({ type: LOGOUT_FAILURE, err }),
    });
  };

export const refreshUser =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { user } = getState().user;

    if (user && token) {
      dispatch({ type: GET_USER_REQUEST });
      return fetch("/users/me/", {
        method: "GET",
        token,
        success: (user) => dispatch({ type: GET_USER_SUCCESS, user }),
        failure: () => dispatch({ type: GET_USER_FAILURE }),
      });
    }
    return user;
  };

export const getUser =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { user } = getState().user;

    if (!user && token) {
      dispatch({ type: GET_USER_REQUEST });
      return fetch("/users/me/", {
        method: "GET",
        token,
        success: (user) => {
          dispatch({ type: GET_USER_SUCCESS, user });
        },
        failure: () => dispatch({ type: GET_USER_FAILURE }),
      });
    } else {
      if (user && user.preferences) {
        if (!user.preferences.dropsperml) {
          user.preferences.dropsperml = 30;
        }
        if (!user.preferences.dropstype) {
          user.preferences.dropstype = 1;
        }
      }
      return user;
    }
  };

export const cancelSubscription =
  () =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { user } = getState().user;

    dispatch({ type: GET_USER_REQUEST });
    return fetch("/users/cancelsubscription/", {
      method: "DELETE",
      token,
      success: (user) => {
        dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS, user });
        message.success("Successfully unsubscribed");
        dispatch(logoutSuccess());
      },
      failure: () => {
        dispatch({ type: CANCEL_SUBSCRIPTION_FAILURE });
        message.success("Cancelling failed.  Please contact Blend Precisely support");
        dispatch(logoutSuccess());
      },
    });
  };

export const setSessionCurrency = (key) => (dispatch, getState) => {
  dispatch({ type: UPDATE_SESSION_CURRENCY, key });
};

export const clearSessionCurrency = (key) => (dispatch, getState) => {
  dispatch({ type: CLEAR_SESSION_CURRENCY });
};

export const updateUser =
  (values, form) =>
  (dispatch, getState, { fetch }) => {
    const { intl } = dispatch(getIntl());
    const { token } = dispatch(getToken());
    dispatch({ type: UPDATE_USER_REQUEST });
    return fetch("/users/me/", {
      method: "PATCH",
      token,
      body: values,
      success: (user) => {
        dispatch({ type: UPDATE_USER_SUCCESS, user });
        dispatch(closeUpdateUserModal());
        message.success(intl.formatMessage(messages.updatingUserSuccess), SHORT_MESSAGE_DELAY);
      },
      failure: (errors) => {
        dispatch({ type: UPDATE_USER_FAILURE });
        const { formErrors } = getFormErrors({ errors, values });
        if (formErrors) {
          form.setFields(formErrors);
        } else {
          message.error(intl.formatMessage(messages.updatingUserError), LONG_MESSAGE_DELAY);
        }
      },
    });
  };

export const updateUserPreferences =
  (values, form) =>
  (dispatch, getState, { history, fetch }) => {
    const { intl } = dispatch(getIntl());
    const { token } = dispatch(getToken());
    dispatch({ type: UPDATE_USER_REQUEST });
    return fetch("/users/me/", {
      method: "PATCH",
      token,
      body: values,
      success: (user) => {
        dispatch({ type: UPDATE_USER_SUCCESS, user });
        const currency = user.preferences.currency;
        if (currency) {
          dispatch(changeCurrency(currency));
        }

        message.success(intl.formatMessage(messages.updatingUserPreferencesSuccess), SHORT_MESSAGE_DELAY);
      },
      failure: (errors) => {
        dispatch({ type: UPDATE_USER_FAILURE });
        const { formErrors } = getFormErrors({ errors, values });
        if (formErrors) {
          form.setFields(formErrors);
        } else {
          message.error(intl.formatMessage(messages.updatingUserError), LONG_MESSAGE_DELAY);
        }
      },
    });
  };

export const getUpgradeLink = (id) => (dispatch, getState) => {
  let { user } = getState().user;
  if (user.is_affiiliate && user.afflinks) {
    const result = user.afflinks.find(({ link }) => link.link_name === "Blend Precisely - Monthly - 14-Day Trial");
    return result;
  }
  return {};
};

export const openUpdateUserModal = () => ({ type: OPEN_UPDATE_USER_MODAL });

export const closeUpdateUserModal = () => ({ type: CLOSE_UPDATE_USER_MODAL });

export const updatePassword =
  (values) =>
  (dispatch, getState, { fetch }) => {
    const { intl } = dispatch(getIntl());
    const { token } = dispatch(getToken());
    dispatch({ type: UPDATE_PASSWORD_REQUEST });
    return fetch("/users/me/change-password/", {
      method: "PATCH",
      token,
      body: values,
      success: () => {
        dispatch({ type: UPDATE_PASSWORD_SUCCESS });
        dispatch(closeUpdatePasswordModal());
        message.success(intl.formatMessage(messages.updatingPasswordSuccess), SHORT_MESSAGE_DELAY);
      },
      failure: (errors) => {
        const { error } = getFormErrors({ errors, values });
        dispatch({ type: UPDATE_PASSWORD_FAILURE, error });
      },
    });
  };

export const openUpdatePasswordModal = () => ({
  type: OPEN_UPDATE_PASSWORD_MODAL,
});

export const closeUpdatePasswordModal = () => ({
  type: CLOSE_UPDATE_PASSWORD_MODAL,
});

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  loading: {
    user: false,
    updatingUser: false,
    updatingPassword: false,
  },
  loggedIn: false,
  error: null,
  user: null,
  refreshingToken: false,
  customer: null,
  updateUserModalOpened: false,
  updatePasswordModalOpened: false,
  sessionCurrency: null,
  cancel_subscription: true,
};

export default createReducer(initialState, {
  [LOGOUT_SUCCESS]: (state, action) => RESET_STORE,
  [CANCEL_SUBSCRIPTION_SUCCESS]: (state, action) => ({
    cancel_subscription: true,
  }),
  [CANCEL_SUBSCRIPTION_FAILURE]: (state, action) => ({
    cancel_subscription: false,
  }),

  [GET_USER_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      user: true,
    },
  }),
  [GET_USER_SUCCESS]: (state, { user }) => ({
    loading: {
      ...state.loading,
      user: false,
    },
    user,
    customer: user.customer,
    loggedIn: true,
  }),
  [GET_USER_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      user: false,
    },
  }),
  [REFRESH_TOKEN_REQUEST]: (state, action) => ({
    refreshingToken: true,
  }),
  [REFRESH_TOKEN_SUCCESS]: (state, action) => ({
    refreshingToken: false,
  }),
  [REFRESH_TOKEN_FAILURE]: (state, action) => ({
    refreshingToken: false,
  }),
  [REFRESH_TOKEN_FAILURE]: (state, action) => ({
    refreshingToken: false,
  }),
  [OPEN_UPDATE_USER_MODAL]: (state, action) => ({
    updateUserModalOpened: true,
  }),
  [CLOSE_UPDATE_USER_MODAL]: (state, action) => ({
    updateUserModalOpened: false,
  }),
  [UPDATE_USER_REQUEST]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingUser: true,
    },
  }),
  [UPDATE_USER_SUCCESS]: (state, { user }) => ({
    user,
    customer: user.customer,
    loading: {
      ...state.loading,
      updatingUser: false,
    },
  }),
  [UPDATE_USER_FAILURE]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingUser: false,
    },
  }),
  [OPEN_UPDATE_PASSWORD_MODAL]: (state, action) => ({
    updatePasswordModalOpened: true,
  }),
  [CLOSE_UPDATE_PASSWORD_MODAL]: (state, action) => ({
    updatePasswordModalOpened: false,
  }),
  [UPDATE_PASSWORD_REQUEST]: (state, action) => ({
    error: null,
    loading: {
      ...state.loading,
      updatingPassword: true,
    },
  }),
  [UPDATE_PASSWORD_SUCCESS]: (state, action) => ({
    loading: {
      ...state.loading,
      updatingPassword: false,
    },
  }),
  [UPDATE_PASSWORD_FAILURE]: (state, { error }) => ({
    error,
    loading: {
      ...state.loading,
      updatingPassword: false,
    },
  }),
  [UPDATE_SESSION_CURRENCY]: (state, { key }) => ({
    sessionCurrency: key,
  }),
  [CLEAR_SESSION_CURRENCY]: (state) => ({
    sessionCurrency: null,
  }),
});
